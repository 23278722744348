import { Blockchain } from "juneojs";
import { MyNode } from "../pages/Stake/Validate/MyNodes/MyNodes";

/**
 * A controller for handling localStorage operations.
 */
class LocalStorageController {
  /**
   * Retrieves an item from localStorage and parses it as JSON.
   * @param key - The key of the item to retrieve.
   * @returns The parsed item or null if not found.
   */
  static getItem<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  /**
   * Serializes a value as JSON and stores it in localStorage.
   * @param key - The key under which to store the value.
   * @param value - The value to store.
   */
  static setItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Removes an item from localStorage.
   * @param key - The key of the item to remove.
   */
  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  /**
   * Retrieves the saved nodes from localStorage.
   * @returns An array of saved nodes.
   */
  static getSavedNodes(): MyNode[] {
    return this.getItem<MyNode[]>(LOCAL_STORAGE_KEYS.savedNodes) || [];
  }

  /**
   * Saves a node to localStorage, replacing any existing node with the same nodeId.
   * @param node - The node to save.
   */
  static saveNode(node: MyNode): void {
    const savedNodes = this.getSavedNodes();
    const filteredNodes = savedNodes.filter(
      (savedNode) => savedNode.nodeId !== node.nodeId,
    );
    this.setItem(LOCAL_STORAGE_KEYS.savedNodes, [...filteredNodes, node]);
  }

  /**
   * Removes a node from localStorage by its nodeId.
   * @param nodeId - The ID of the node to remove.
   */
  static removeNode(nodeId: string): void {
    const savedNodes = this.getSavedNodes();
    const updatedNodes = savedNodes.filter((node) => node.nodeId !== nodeId);
    this.setItem(LOCAL_STORAGE_KEYS.savedNodes, updatedNodes);
  }

  /**
   * Retrieves the selected blockchain from localStorage.
   * @returns The selected blockchain or null if not found.
   */
  static getSelectedBlockchain(): Blockchain | null {
    return this.getItem<Blockchain>(LOCAL_STORAGE_KEYS.chain);
  }

  /**
   * Saves the selected blockchain to localStorage.
   * @param blockchain - The blockchain to save.
   */
  static saveSelectedBlockchain(blockchain: Blockchain): void {
    this.setItem(LOCAL_STORAGE_KEYS.chain, blockchain.id);
  }

  /**
   * Retrieves the wallet name from localStorage.
   * @param address - The address of the wallet.
   * @returns The wallet name or null if not found.
   */
  static getWalletName(address: string): string | null {
    const name = localStorage.getItem(address);
    try {
      return name ? JSON.parse(name) : null;
    } catch {
      return name;
    }
  }

  /**
   * Saves the wallet name to localStorage.
   * @param address - The address of the wallet.
   * @param name - The name to save.
   */
  static saveWalletName(address: string, name: string): void {
    this.setItem(address, name);
  }

  /**
   * Retrieves the favorites from localStorage.
   * @param networkName - The name of the network.
   * @returns The favorites or an empty array if not found.
   */
  static getFavorites(networkName: string): string[] {
    return this.getItem<string[]>(`favorites-${networkName}`) || [];
  }

  /**
   * Saves the favorites to localStorage.
   * @param networkName - The name of the network.
   * @param favorites - The favorites to save.
   */
  static saveFavorites(networkName: string, favorites: string[]): void {
    this.setItem(`favorites-${networkName}`, favorites);
  }
}

export const LOCAL_STORAGE_KEYS = {
  erc20Icons: `erc20Icons`,
  blockchainIcons: `blockchainIcons`,
  savedNodes: `savedNodes`,
  showOnlyMySavedNodes: `showOnlyMySavedNodes`,
  chain: `chain`,
  disableStayConnected: `disableStayConnected`,
  stayConnectedDuration: `stayConnectedDuration`,
  showAllsNodes: `showAllsNodes`,
  hideZeroBalances: `hideZeroBalances`,
};

export default LocalStorageController;
